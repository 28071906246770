export const dateToCountdown = (
  date: string | Date,
  startDate: Date,
  secondsElapsed: number,
  options?: { showDays?: boolean }
): string => {
  const realDate = new Date(date);
  const diff = realDate.getTime() - startDate.getTime() - secondsElapsed * 1000;
  if (diff < 0) {
    return "00:00:00";
  }

  const days = String(Math.floor(diff / (1000 * 60 * 60 * 24))).padStart(
    2,
    "0"
  );
  const hours = options?.showDays
    ? String(Math.floor(diff / (1000 * 60 * 60)) % 24).padStart(2, "0")
    : String(Math.floor(diff / (1000 * 60 * 60))).padStart(2, "0");

  const minutes = String(Math.floor((diff / (1000 * 60)) % 60)).padStart(
    2,
    "0"
  );
  const seconds = String(Math.floor((diff / 1000) % 60)).padStart(2, "0");

  return `${options?.showDays ? `${days}:` : ""}${hours}:${minutes}:${seconds}`;
};

export const dateToCountdownObject = (
  date: string | Date,
  startDate: Date,
  secondsElapsed: number
): { hours: string; minutes: string; seconds: string } => {
  const countdown = dateToCountdown(date, startDate, secondsElapsed, {
    showDays: false,
  }).split(":");
  return {
    // days: countdown[0],
    hours: countdown[0],
    minutes: countdown[1],
    seconds: countdown[2],
  };
};

export const numberToOrder = (num: number): string => {
  const lastDigit = num % 10;
  const lastTwoDigits = num % 100;
  if (lastTwoDigits >= 10 && lastTwoDigits <= 20) {
    return `${num}th`;
  }
  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};

export const validateEmail = (email: string): boolean => {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
