import { useNavigate } from "react-router-dom";
import HeaderButton from "../HeaderButton";
import Logo from "../../assets/logo.svg";
import "./styles.scss";

import MapsIcon from "./assets/btnMaps.svg";
import BuildsIcon from "./assets/btnBuilds.svg";
import GuildsIcon from "./assets/btnGuilds.svg";
import MyProfileIcon from "./assets/btnProfile.svg";
import HamburgerIcon from "../../assets/hamburger.svg";
import CloseburgerIcon from "../../assets/closeburger.svg";
import { useToasts } from "../../contexts/toasts";
import { useState } from "react";
import GenericButton from "../GenericButton";

interface HeaderProps {
  hasGuilds: boolean;
}

const Header = (_props: HeaderProps) => {
  const navigate = useNavigate();
  const toasts = useToasts();
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <div className="header-container">
      <div className="header-inner">
        <h2
          id="logo"
          onClick={() => {
            navigate("/");
          }}
        >
          Diablo&nbsp;4<div className="subtitle">Guilds</div>
        </h2>
        <img
          src={HamburgerIcon}
          className="mobile-only hamburger"
          alt="Menu"
          onClick={() => setShowMobileMenu(true)}
        />
        <nav className="nav-header">
          <div className="row">
            <HeaderButton
              label="Maps"
              icon={MapsIcon}
              style={{ animationDelay: "0.1s" }}
              onClick={() => {
                window.location.href = "https://diablo4map.com/";
              }}
            />
            {/* <HeaderButton
            label="Builds"
            icon={BuildsIcon}
            style={{ animationDelay: "0.2s" }}
          /> */}
            <HeaderButton
              label="Guilds"
              icon={GuildsIcon}
              style={{ animationDelay: "0.3s" }}
              onClick={() => {
                navigate("/");
              }}
            />
            <HeaderButton
              label="My Profile"
              icon={MyProfileIcon}
              style={{ animationDelay: "0.4s" }}
              onClick={() => {
                navigate("/profile");
              }}
            />
            <HeaderButton
              label="Products"
              style={{ animationDelay: "0.5s" }}
              onClick={() => {
                navigate("/products");
              }}
            />
            <HeaderButton
              label="Contact Us"
              style={{ animationDelay: "0.6s" }}
              onClick={() => {
                toasts.add({
                  type: "info",
                  message: "Feature coming soon!",
                  duration: 3000,
                  sameId: "contact-us",
                });
              }}
            />
          </div>
          <div className="row">
            <HeaderButton
              label="FAQ"
              style={{ animationDelay: "0.7s" }}
              onClick={() => {
                navigate("/faq");
              }}
            />
            <HeaderButton
              label="How to promote your guild"
              style={{ animationDelay: "0.8s" }}
              onClick={() => {
                navigate("/how-to-promote-your-diablo-4-guild");
              }}
            />
            <HeaderButton
              label="How to level in Diablo 4"
              style={{ animationDelay: "0.9s" }}
              onClick={() => {
                navigate("/how-to-level-in-diablo-4");
              }}
            />
            <HeaderButton
              label="LHM.gg - The best esport solution"
              style={{ animationDelay: "1.0s" }}
              onClick={() => {
                navigate(
                  "/lhm-gg-the-best-esport-production-and-observer-overlay-solution"
                );
              }}
            />
          </div>
        </nav>
      </div>
      {showMobileMenu && (
        <div className="mobile-menu-container mobile-only">
          <div className="mobile-menu-top">
            <h2
              id="logo"
              onClick={() => {
                navigate("/");
              }}
            >
              Diablo&nbsp;4<div className="subtitle">Guilds</div>
            </h2>
            <img
              src={CloseburgerIcon}
              className="hamburger"
              alt="Menu"
              onClick={() => setShowMobileMenu(false)}
            />
          </div>
          <div className="mobile-menu">
            <GenericButton
              label="Maps"
              icon={MapsIcon}
              onClick={() => {
                window.location.href = "https://diablo4map.com/";
              }}
            />
            <GenericButton
              label="Guilds"
              icon={GuildsIcon}
              onClick={() => {
                navigate("/");
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="My Profile"
              icon={MyProfileIcon}
              onClick={() => {
                navigate("/profile");
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="Products"
              onClick={() => {
                navigate("/products");
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="Contact Us"
              onClick={() => {
                toasts.add({
                  type: "info",
                  message: "Feature coming soon!",
                  duration: 3000,
                  sameId: "contact-us",
                });
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="FAQ"
              onClick={() => {
                navigate("/faq");
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="How to promote your guild"
              onClick={() => {
                navigate("/how-to-promote-your-diablo-4-guild");
                setShowMobileMenu(false);
              }}
            />
            <GenericButton
              label="How to level in Diablo 4"
              onClick={() => {
                navigate("/how-to-level-in-diablo-4");
                setShowMobileMenu(false);
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
