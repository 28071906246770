import "./App.scss";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { GuildProvider } from "./contexts/guild";
import { GuildsProvider } from "./contexts/guilds";

import MainSection from "./routes/Main";
import GuildSection from "./routes/Guild";
import Layout from "./components/Layout";
import { PlatformsProvider } from "./contexts/platforms";
import { LanguagesProvider } from "./contexts/languages";
import AuthGuard from "./routes/AuthGuard";
import LoginRegisterSection from "./routes/LoginRegister";
import { UserProvider } from "./contexts/user";
import GuildManagementSection from "./routes/GuildManagement";
import GuildApplySection from "./routes/GuildApply";
import ProfileSection from "./routes/Profile";
import { ProfileProvider } from "./contexts/profile";
import ProfileEditSection from "./routes/ProfileEdit";
import ProductsSection from "./routes/Products";
import { ToastsProvider } from "./contexts/toasts";
import FAQSection from "./routes/FAQ";
import PromoteYourGuildSection from "./routes/PromoteYourGuild";
import HowToLevelSection from "./routes/HowToLevel";
import LHMSection from "./routes/LHMSection";

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <Layout />,
      children: [
        { path: "/", element: <MainSection /> },
        { path: "/faq", element: <FAQSection /> },
        { path: "/how-to-level-in-diablo-4", element: <HowToLevelSection /> },
        {
          path: "/lhm-gg-the-best-esport-production-and-observer-overlay-solution",
          element: <LHMSection />,
        },
        {
          path: "/how-to-promote-your-diablo-4-guild",
          element: <PromoteYourGuildSection />,
        },
        { path: "/guilds/:id", element: <GuildSection /> },
        {
          path: "/guilds/:id/manage",
          element: <AuthGuard />,
          children: [
            { path: "/guilds/:id/manage", element: <GuildManagementSection /> },
          ],
        },
        {
          path: "/guilds/:id/apply",
          element: <AuthGuard />,
          children: [
            { path: "/guilds/:id/apply", element: <GuildApplySection /> },
          ],
        },
        {
          path: "/guild-create",
          element: <AuthGuard />,
          children: [
            { path: "/guild-create", element: <GuildManagementSection /> },
          ],
        },
        {
          path: "/login",
          element: <LoginRegisterSection />,
        },
        {
          path: "/register",
          element: <LoginRegisterSection />,
        },
        {
          path: "/profile",
          element: <AuthGuard />,
          children: [{ path: "/profile", element: <ProfileSection /> }],
        },
        {
          path: "/profile/edit",
          element: <AuthGuard />,
          children: [
            { path: "/profile/edit", element: <ProfileEditSection /> },
          ],
        },
        {
          path: "/profile/:id",
          element: <ProfileSection />,
        },
        {
          path: "/products",
          element: <ProductsSection />,
        },
      ],
    },
  ],
  {}
);

function App() {
  return (
    <ToastsProvider>
      <UserProvider>
        <PlatformsProvider>
          <LanguagesProvider>
            <GuildsProvider>
              <GuildProvider>
                <ProfileProvider>
                  <RouterProvider router={router} />
                </ProfileProvider>
              </GuildProvider>
            </GuildsProvider>
          </LanguagesProvider>
        </PlatformsProvider>
      </UserProvider>
    </ToastsProvider>
  );
}

export default App;
