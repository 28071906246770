const events: Array<{ name: string; date: Date }> = [];

const recurringEvents: Array<{
  name: string;
  baseDate: Date;
  frequency: number;
}> = [
  {
    name: "Helltide",
    baseDate: new Date(1686830400000),
    frequency: 2 * 60 * 60 * 1000,
  },
];

export const getNextEvent = () => {
  const now = new Date();
  const event = events.find((event) => event.date > now);

  const nextRecurringEvents = recurringEvents.map((event) => {
    const now = new Date();
    const baseDate = new Date(event.baseDate);
    const frequency = event.frequency;

    let time = baseDate.getTime();
    while (time < now.getTime()) {
      time += frequency;
    }

    return { name: event.name, date: new Date(time) };
  });

  if (event) {
    nextRecurringEvents.push(event);
  }

  return nextRecurringEvents.sort(
    (a, b) => a.date.getTime() - b.date.getTime()
  )[0];
};
