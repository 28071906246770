import { useState } from "react";
import SectionHeader from "../../components/SectionHeader";

import "./styles.scss";

interface ExLinkProps extends React.HTMLProps<HTMLAnchorElement> {}
const ExLink = ({ href, children }: ExLinkProps) => (
  <a href={"https://lhm.gg" + href} target="_blank" rel="noreferrer">
    {children}
  </a>
);

const LHMSection = () => {
  const [show, setShow] = useState(false);

  return (
    <div className="htl-section">
      <SectionHeader title="LHM.gg: the best esport production and observer overlay solution for CS2, Dota 2, League of Legends, and Valorant" />
      <div className="container">
        <p>
          LHM.gg is a comprehensive, cloud-based application designed to
          revolutionize esports broadcasting and production. Developed by
          Lexogrine, it offers a suite of advanced AI-driven tools and features
          that streamline the management of live esports events across multiple
          popular titles, including Counter-Strike 2 (CS2), Dota 2, League of
          Legends, Valorant, Rocket League, and Apex Legends.
        </p>

        <div className="section">
          <h2>Key Features of LHM.gg</h2>
          <ul>
            <li>
              <strong>Automated Observing with Scout AI:</strong> LHM.gg's{" "}
              <ExLink href="/features/scout-ai">Scout AI</ExLink> automates up
              to 80% of the observing process in games like CS2 and Dota 2. It
              intelligently predicts in-game events, directing camera focus to
              crucial moments without human intervention.
            </li>
            <li>
              <strong>Multiple HUD Support:</strong> The platform provides
              support for multiple Heads-Up Displays (HUDs), including{" "}
              <ExLink href="/features/ultra-hud/cs2">Ultra</ExLink> and{" "}
              <ExLink href="/features/premium-hud/cs2">Premium HUDs</ExLink>,
              offering broadcasters a range of visual options to enhance viewer
              engagement.
            </li>
            <li>
              <strong>
                Custom Control Settings and Easy HUD Development Environment:
              </strong>{" "}
              LHM.gg allows users to tailor control settings to their specific
              requirements and offers an easy-to-use HUD development
              environment.
            </li>
            <li>
              <strong>Match Data Tracking and Live Overview of Players:</strong>{" "}
              The application tracks match data in real-time, providing
              broadcasters with live overviews of player statistics and game
              progress.
            </li>
            <li>
              <strong>
                Remote HUD Access and Custom Player Camera System:
              </strong>{" "}
              LHM.gg facilitates remote access to HUDs and offers a custom{" "}
              <ExLink href="/features/hud-cameras">player camera system</ExLink>{" "}
              for dynamic camera angles.
            </li>
            <li>
              <strong>Cloud Storage for Assets and Settings:</strong> Users can
              store and manage assets and settings securely{" "}
              <ExLink href="/features/cloud-storage">in the cloud</ExLink>.
            </li>
            <li>
              <strong>
                Multilanguage Support and Stream Deck Integration:
              </strong>{" "}
              LHM.gg offers multilanguage support and integrates with devices
              like the Stream Deck for streamlined control.
            </li>
            <li>
              <strong>LHM Veto:</strong> This feature simplifies the{" "}
              <ExLink href="/features/veto">map veto</ExLink>
              process during live events.
            </li>
            <li>
              <strong>Automatic Replay Generator (LHM Replays):</strong>{" "}
              LHM.gg's <ExLink href="/features/replays">Replays</ExLink>{" "}
              captures the best moments of the game based on predefined
              criteria.
            </li>
            <li>
              <strong>LHM Cameras:</strong> The platform offers a customizable{" "}
              <ExLink href="/features/hud-cameras">camera system</ExLink> that
              supports configurable camera delays.
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>Case Study: Relog Media</h2>
          <p>
            Relog Media, a prominent esports company, has effectively utilized
            LHM.gg to enhance its production capabilities. By integrating LHM's
            all-in-one software and cloud system,{" "}
            <ExLink href="/casestudies/relog-media">
              Relog Media streamlined its operations
            </ExLink>
            , achieving a new level of efficiency in managing multinational
            tournaments and events.
          </p>
        </div>

        <div className="section">
          <h2>Pricing Plans</h2>
          <ul>
            <li>
              <strong>Personal Plan:</strong> Suitable for individual
              broadcasters, this plan provides access to standard HUD
              functionality, default game color palettes, and 2 hours of Scout
              AI per month.
            </li>
            <li>
              <strong>Professional Plan:</strong> Designed for professional
              streamers and small production teams, it includes additional
              features such as advanced HUD options, increased Scout AI hours,
              and extended cloud storage for assets and settings.
            </li>
            <li>
              <strong>Enterprise Plan:</strong> Tailored for large organizations
              and esports companies, this plan offers comprehensive access to
              all features, including increased Scout AI hours, user and
              permissions management, workspaces, Replays, and unlimited Signals
              (starting from 3 seats).
            </li>
          </ul>
        </div>

        <div className="section">
          <h2>Community and Support</h2>
          <p>
            LHM.gg maintains an active community and offers extensive support to
            its users. Developers and broadcasters can access APIs and advanced
            AI and cloud features to fully leverage the platform's capabilities.
            The development team is in constant contact with the community,
            providing near 24/7 support and regularly updating the platform
            based on user feedback.
          </p>
        </div>

        <div className="section">
          <h2>Conclusion</h2>
          <p>
            LHM.gg stands as a powerful tool in the esports industry, offering a
            comprehensive suite of features that automate and enhance
            broadcasting and production processes. Its advanced AI capabilities,
            customizable HUDs, and user-friendly interface make it an invaluable
            asset for broadcasters, observers, players, teams, and tournament
            organizers. By streamlining operations and providing innovative
            solutions, LHM.gg contributes significantly to the evolution of
            esports production, setting new standards for quality and efficiency
            in the field.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LHMSection;
